import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import {Button} from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
const Footer = () => {
    return ( 
        <div className='footer-container'>
        <div>
            
        <ul style={{textAlign:'center'}}>
        <label htmlFor="" style={{fontWeight:'bold',fontSize:"1.5rem"}}>ΜΕΣΑ ΚΟΙΝΩΝΙKHΣ ΔΙΚΤΥΩΣΗΣ</label>
                <li><IconButton><FacebookIcon sx={{ fontSize: 40 }}/></IconButton></li>
                <li><IconButton><InstagramIcon sx={{ fontSize: 40 }}/></IconButton></li>
            </ul>
        </div>
        <div>
           
        <ul style={{textAlign:'center'}}>
        <label htmlFor="" style={{fontWeight:'bold',fontSize:"1.6rem"}}>ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</label>
                <li><a href='tel:+306945785757' style={{textDecoration:'none',color:'black',fontSize:'1.6rem'}}><IconButton ><PhoneIcon sx={{ fontSize: 40 }}/></IconButton><br/>+30 6945785757</a></li>
                <li><a href="mailto:zannias.woodworking@gmail.com" style={{textDecoration:'none',color:'black',fontSize:'1.6rem'}}><IconButton><EmailIcon sx={{ fontSize: 40 }}/></IconButton><br/>zannias.woodworking@gmail.com</a></li>
            </ul>
        </div>
    </div>  
     );
}
 
export default Footer;