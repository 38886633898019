import React, { useState, useContext, useEffect, useCallback, useMemo} from 'react';
import { Link } from 'react-router-dom';
import {Button} from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';

const Header = () => {
    return ( 
        <div className='header-container' >
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'20%'}}>

                <IconButton href='https://www.facebook.com/profile.php?id=100027111576421' target="_blank" style={{color:'white',width:'50%'}}><FacebookIcon  style={{color:'white'}} sx={{ fontSize: 40 }}/></IconButton>
                <IconButton href='https://www.facebook.com/profile.php?id=100027111576421' target="_blank" style={{color:'white',width:'50%'}}><InstagramIcon   style={{color:'white'}} sx={{ fontSize: 40 }}/></IconButton>

            </div> 
            <div style={{textAlign:'center',display:'flex',flexDirection:'column',width:'60%',alignItems:'center',justifyContent:'space-evenly'}}>
            <h2   style={{textDecoration:'none',color:'white',fontSize:'2.4rem',fontFamily: '"Noto Serif Display" ,serif'}}><Link to='/' style={{textDecoration:'none',color:'white',  textShadow:' 1px 1px #000'}}>ΓΙΩΡΓΟΣ ΖΑΝΙΑΣ</Link></h2>
            <span style={{textDecoration:'none',color:'white',fontSize:'1.1rem',  textShadow:' 2px 2px #000',fontFamily: '"Noto Serif Display" ,serif'}}>Δια Χειρώς...</span>
            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'20%'}}>
                <a href='tel:+306945785757' style={{textDecoration:'none',color:'white',fontSize:'1.5rem',  textShadow:' 2px 2px #000'}}><IconButton style={{width:'100%'}} ><PhoneIcon  style={{color:'white'}} sx={{ fontSize: 40 }}/></IconButton><br/>+30 6945785757</a>
            </div> 
        </div>  
     );
}
 
export default Header;