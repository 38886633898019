import logo from './logo.svg';
import './App.css';
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


import Footer from './components/Footer'
import Homepage from './components/Homepage'
import Contact from './components/Contact'

function App() {
  return (
    <Router>

      <Switch>
        <Route exact path='/' render={()=>{
          return (<main className='aside'><Homepage/></main>)
        }}/>
        <Route exact path='/contact' render={()=>{
          return (<main className='aside'><Contact/></main>)
        }}/>
        <Route path='/' render={()=>{
         return (<main className={'aside'}><h1>Page Not Found</h1></main>)
        }}/>
      </Switch>
      <footer  className='bottom'><Footer/></footer>
    </Router>
  );
}

export default App;
