import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import image from '../teixos.jpg'
import image2 from '../kouzina1.jpg'
import image3 from '../kouzina2.jpg'
import image4 from '../door.jpg'
import image5 from '../ntoulapa.jpg'
import Header from './Header'
const Homepage = () => {
    return ( 
        <div className='homepage-container'>
                  
            <section className='first' >
            <header className='top'><Header/></header>
            <div className='cont' style={{width:'100%',height:'85vh',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',color:'white'}}>
                <div>
                    <h1 style={{textAlign:'center',fontSize:'2.5rem'}} className="start">ΞΥΛΟΥΡΓΙΚΕΣ ΕΡΓΑΣΙΕΣ, ΚΑΤΑΣΚΕΥΕΣ, ΕΠΙΣΚΕΥΕΣ</h1>
                </div>
                <div>
                    <h2 style={{fontSize:'1.6rem',wordWrap:'break-word',textAlign:'center'}} className="start">ΣΥΝΑΡΜΟΛΟΓΗΣΗ ΚΑΙ ΤΟΠΟΘΕΤΗΣΗ ΚΑΙ ΕΠΙΔΙΟΡΘΩΣΗ ΠΑΣΗΣ ΦΥΣΕΩΣ ΞΥΛΙΝΩΝ ΚΑΤΑΣΚΕΥΩΝ</h2>
                </div>
                </div>
            </section>
            <section className='fourth'>
                <div className='container-texts' style={{width:'60%',textAlign:'center'}}>
                    <h2 style={{textDecoration:'none',fontSize:'2rem'}}>ΠΟΙΟΙ ΕΙΜΑΣΤΕ</h2>

                    <p style={{wordWrap:'break-word',fontSize:'1.5rem'}}>Εμπορευόμαστε και συναρμολογούμε αρίστης ποιότητας και σύστασης ξύλινα έπιπλα για όλο το σπίτι. Εξειδικευόμαστε στις κουζίνες, στις πόρτες (και θωρακισμένες εξώπορτες), στα ντουλάπια και γραφεία. Προσπαθούμε τα μέγιστα για να δώσουμε στο έπιπλο την αξιοπιστία, τη στιβαρότητα και την αισθητική που όλοι θέλουμε να έχουμε σπίτι μας, αλλά και στο γραφείο. Η εμπειρία μας στον εργασιακό χώρο και στο ξύλο έχει βαθιές ρίζες στο χρόνο. Κάνουμε τη δουλειά μας με αγάπη και όρεξη.</p>
                    </div>
            </section>
            <section className='second'>
                <div className='container-image' style={{width:'40%',height:'auto' ,maxHeight:'50%'}}>
                    <img style={{width:'100%',height:'auto',maxWidth:'600px',maxHeight:'600px'}} src={image3}/>
                </div>
                <div className='container-texts' style={{width:'40%',textAlign:'center',wordWrap:'break-word'}}>
                    <h3 style={{textDecoration:'none',fontSize:'2rem'}}>ΞΥΛΙΝΕΣ ΚΟΥΖΙΝΕΣ</h3>
                    <p style={{textDecoration:'none',fontSize:'1.5rem'}}>Ξύλινη κουζίνα εμπνευσμένη, συναρμολογημένη και τοποθετημένη από εμάς, προσαρμοσμένη στα πιο σύγχρονα γούστα πάντα από τα πιο ποιοτητά, διαλεγμένα υλικά και εξελιγμένους μηχανισμούς.</p>
                </div>
            </section>
            <section className='third'>

                <div className='container-image' style={{width:'40%',height:'auto' ,maxHeight:'50%'}}>
                    <img style={{width:'100%',height:'auto',maxWidth:'100%',maxHeight:'100%'}} src={image2}/>
                </div>
                <div className='container-texts' style={{width:'40%',textAlign:'center',wordWrap:'break-word'}}>
                    <h3 style={{textDecoration:'none',fontSize:'2rem'}}>ΓΩΝΙΑΚΗ ΚΟΥΖΙΝΑ ΜΕ ΝΤΟΥΛΑΠΙΑ</h3>
                    <p style={{textDecoration:'none',fontSize:'1.5rem'}}>Η εργασιακή μας παλέτα περιλαμβάνει και κουζίνες τοποθετημένες σε γωνία. Ντυμένες πάντα με τα χρώματα της επιλογής σας και επικαλυμμένες από σκληρούς και ανθεκτικούς πάγκους</p>
                </div>
            </section>
            <section className='second'>
                <div className='container-image' style={{width:'40%',height:'auto',maxHeight:'50%'}}>
                    <img style={{width:'100%',height:'auto' ,maxWidth:'100%',maxHeight:'100%'}} src={image}/>
                </div>
                <div className='container-texts' style={{width:'40%',textAlign:'center',wordWrap:'break-word'}}>
                    <h3 style={{textDecoration:'none',fontSize:'2rem'}}>ΝΤΥΣΙΜΟ ΤΟΙΧΟΥ</h3>
                    <p style={{textDecoration:'none',fontSize:'1.5rem'}}>Καλύπτουμε τον άδειο τοίχο σας με ποικίλα ξύλινα κατασκευάσματα. Προσφέρουμε και δικές μας προτάσεις.</p>
                </div>
            </section>
            <section className='third'>

                <div className='container-image' style={{width:'40%',height:'auto' ,maxHeight:'50%'}}>
                    <img style={{width:'100%',height:'auto',maxWidth:'550px',maxHeight:'auto'}} src={image4}/>
                </div>
                <div className='container-texts' style={{width:'40%',textAlign:'center',wordWrap:'break-word'}}>
                    <h3 style={{textDecoration:'none',fontSize:'2rem'}}>ΠΟΡΤΕΣ ΑΣΦΑΛΕΙΑΣ</h3>
                    <p style={{textDecoration:'none',fontSize:'1.5rem'}}>Οι θωρακισμένες πόρτες ασφαλείας μας παρέχουν ηρεμία καθώς απαλλάσουν από τον φόβο ενός πιθανού εισβολέα. Επιπρόσθετα ανακουφίζουν από ηχορύπανση, όντας φτιαγμένες για να μονώνουν το δυνατόν περισσότερο τους ήχους.</p>
                </div>
                </section>
                <section className='second'>
                <div className='container-image' style={{width:'40%',height:'auto',maxHeight:'50%'}}>
                    <img style={{width:'100%',height:'auto' ,maxWidth:'550px',maxHeight:'600px'}} src={image5}/>
                </div>
                <div className='container-texts' style={{width:'40%',textAlign:'center',wordWrap:'break-word'}}>
                    <h3 style={{textDecoration:'none',fontSize:'2rem'}}>ΕΝΤΟΙΣΜΕΝΕΣ ΝΤΟΥΛΑΠΕΣ</h3>
                    <p style={{textDecoration:'none',fontSize:'1.5rem'}}>Ένα άνετο και καλαίσθητο κατασκεύασμα για να τακτοποιείτε τη γκαρνταρόμπα σας.</p>
                </div>
            </section>
        </div>
     );
}
 
export default Homepage;